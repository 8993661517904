import React, { useState } from "react";
import { Modal, Form, InputNumber } from "antd";

const EditHours = ({ visible, selectedPLPs, onSubmit }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);

  let initialValues = selectedPLPs.reduce(function (
    initialValues,
    selectedPLP
  ) {
    if (!initialValues.serviceHour) {
      initialValues.serviceHour = selectedPLP.serviceHour;
    } else {
      if (initialValues.serviceHour !== selectedPLP.serviceHour) {
        initialValues.serviceHour = null;
      }
    }

    if (!initialValues.dtpHour) {
      initialValues.dtpHour = selectedPLP.dtpHour;
    } else {
      if (initialValues.dtpHour !== selectedPLP.dtpHour) {
        initialValues.dtpHour = null;
      }
    }

    if (!initialValues.engHour) {
      initialValues.engHour = selectedPLP.engHour;
    } else {
      if (initialValues.engHour !== selectedPLP.engHour) {
        initialValues.engHour = null;
      }
    }

    return initialValues;
  },
  {});

  const [form] = Form.useForm();

  return (
    <Modal
      open={visible}
      title="Edit Hours"
      okText="OK"
      cancelText="Cancel"
      onCancel={async () => {
        await onSubmit(false);
      }}
      onOk={async () => {
        try {
          let values = await form.validateFields();
          setConfirmLoading(true);
          await onSubmit(true, values);
          setConfirmLoading(false);
          form.resetFields();
        } catch (error) {
          console.log("Validate Failed:", error);
        }
      }}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        layout="vertical"
        name="editHours"
        initialValues={{
          modifier: "public",
          serviceHours: initialValues.serviceHour,
          dtpHours: initialValues.dtpHour,
          engineeringHours: initialValues.engHour,
        }}
      >
        <Form.Item
          name="serviceHours"
          label="Service Hours"
          rules={[
            { required: true, message: "Please enter the service hours" },
          ]}
        >
          <InputNumber step={0.01} />
        </Form.Item>

        <Form.Item
          name="dtpHours"
          label="DTP Hours"
          rules={[{ required: true, message: "Please enter the DTP Hours" }]}
        >
          <InputNumber step={0.01} />
        </Form.Item>

        <Form.Item
          name="engineeringHours"
          label="Engineering Hours"
          rules={[
            { required: true, message: "Please enter the engineering hours" },
          ]}
        >
          <InputNumber step={0.01} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditHours;
