export interface Data {
  key: string;
  value: string;
  title: string;
}

export interface Language {
  code: string;
  name: string;
}

const translator: Data = {
  key: "Translator",
  value: "Translator",
  title: "Translator",
};

const languageLead: Data = {
  key: "Language Lead",
  value: "Language_Lead",
  title: "Language Lead",
};

const projectManager: Data = {
  key: "Project Manager",
  value: "Project_Manager",
  title: "Project Manager",
};

const talentManager: Data = {
  key: "Talent Manager",
  value: "Talent_Manager",
  title: "Talent Manager",
};

const techServices: Data = {
  key: "Tech Services",
  value: "Tech_Services",
  title: "Tech Services",
};

const techSupport: Data = {
  key: "Tech Support",
  value: "Tech_Support",
  title: "Tech Support",
};

const tester: Data = {
  key: "Tester",
  value: "Tester",
  title: "Tester",
};

const testLead: Data = {
  key: "Test Lead",
  value: "Test_Lead",
  title: "Test Lead",
};

const subtitling: Data = {
  key: "LQE Subtitling",
  value: "LQE_Subtitling",
  title: "LQE Subtitling",
};

const nonSubtitling: Data = {
  key: "LQE Non Subtitling",
  value: "LQE_Non_Subtitling",
  title: "LQE Non Subtitling",
};

const all: Data = {
  key: "All",
  value: "All",
  title: "All",
};

const lifeSciences: Data = {
  key: "Life Sciences",
  value: "Life_Sciences",
  title: "Life Sciences",
};

export const programRoles: Data[] = [
  translator,
  languageLead,
  {
    key: "GDT",
    value: "GDT",
    title: "GDT",
  },
  projectManager,
  talentManager,
  techServices,
  techSupport,
  tester,
  testLead,
  subtitling,
  nonSubtitling,
];

export const roles: Data[] = [
  {
    key: "Linguist",
    value: "Linguist",
    title: "Linguist",
  },
  projectManager,
  talentManager,
  techServices,
  techSupport,
  tester,
  testLead,
  {
    key: "GDT Lead",
    value: "GDT_Lead",
    title: "GDT Lead",
  },
  subtitling,
  nonSubtitling,
];

export const languages: Language[] = [
  { code: "af", name: "" },
  { code: "ak", name: "" },
  { code: "am", name: "" },
  { code: "ar", name: "" },
  { code: "ar-EG", name: "" },
  { code: "ar-JO", name: "" },
  { code: "ar-MA", name: "" },
  { code: "as", name: "" },
  { code: "az", name: "" },
  { code: "be", name: "" },
  { code: "bg", name: "" },
  { code: "bn", name: "" },
  { code: "bs", name: "" },
  { code: "ca", name: "" },
  { code: "crs", name: "" },
  { code: "chr", name: "" },
  { code: "cs", name: "" },
  { code: "cy", name: "" },
  { code: "da", name: "" },
  { code: "de", name: "" },
  { code: "de-CH", name: "" },
  { code: "de-AT", name: "" },
  { code: "el", name: "" },
  { code: "en", name: "" },
  { code: "en-AU", name: "" },
  { code: "en-CA", name: "" },
  { code: "en-GB", name: "" },
  { code: "en-IN", name: "" },
  { code: "es", name: "" },
  { code: "es-419", name: "" },
  { code: "et", name: "" },
  { code: "eu", name: "" },
  { code: "fa", name: "" },
  { code: "fi", name: "" },
  { code: "fil", name: "" },
  { code: "fr", name: "" },
  { code: "fr-CA", name: "" },
  { code: "ga", name: "" },
  { code: "gl", name: "" },
  { code: "gu", name: "" },
  { code: "ha-NG", name: "" },
  { code: "hi", name: "" },
  { code: "hmn", name: "" },
  { code: "hr", name: "" },
  { code: "ht", name: "" },
  { code: "hu", name: "" },
  { code: "hy", name: "" },
  { code: "id", name: "" },
  { code: "ig", name: "" },
  { code: "is", name: "" },
  { code: "it", name: "" },
  { code: "iw", name: "" },
  { code: "ja", name: "" },
  { code: "jv", name: "" },
  { code: "ka", name: "" },
  { code: "kk", name: "" },
  { code: "km", name: "" },
  { code: "kn", name: "" },
  { code: "ko", name: "" },
  { code: "ky", name: "" },
  { code: "lo", name: "" },
  { code: "lt", name: "" },
  { code: "lv", name: "" },
  { code: "mk", name: "" },
  { code: "ml", name: "" },
  { code: "mn", name: "" },
  { code: "mr", name: "" },
  { code: "ms", name: "" },
  { code: "mt", name: "" },
  { code: "my", name: "" },
  { code: "ne", name: "" },
  { code: "nl", name: "" },
  { code: "nn", name: "" },
  { code: "no", name: "" },
  { code: "or", name: "" },
  { code: "pa", name: "" },
  { code: "pl", name: "" },
  { code: "prs", name: "" },
  { code: "ps", name: "" },
  { code: "pt-BR", name: "" },
  { code: "pt-PT", name: "" },
  { code: "ro", name: "" },
  { code: "ru", name: "" },
  { code: "si", name: "" },
  { code: "sk", name: "" },
  { code: "sl", name: "" },
  { code: "sq", name: "" },
  { code: "sr", name: "" },
  { code: "sv", name: "" },
  { code: "sw", name: "" },
  { code: "ta", name: "" },
  { code: "te", name: "" },
  { code: "tg", name: "" },
  { code: "th", name: "" },
  { code: "tk", name: "" },
  { code: "tr", name: "" },
  { code: "uk", name: "" },
  { code: "ur", name: "" },
  { code: "uz", name: "" },
  { code: "vi", name: "" },
  { code: "xh", name: "" },
  { code: "yue-HK", name: "" },
  { code: "yo", name: "" },
  { code: "zh-CN", name: "" },
  { code: "zh-HK", name: "" },
  { code: "zh-TW", name: "" },
  { code: "zu", name: "" },
  { code: "GDT", name: "" },
  { code: "Other", name: "" },
];

export const legoDragonLevels: Data[] = [
  translator,
  languageLead,
  subtitling,
  nonSubtitling,
  {
    key: "Google Mandatory Trainings - Non Linguist (i.e. PM, TM, GDT, Tech Services)",
    value:
      "Google Mandatory Trainings - Non Linguist (i.e. PM, TM, GDT, Tech Services)",
    title:
      "Google Mandatory Trainings - Non Linguist (i.e. PM, TM, GDT, Tech Services)",
  },
];

export const productLine: Data[] = [
  all,
  {
    key: "ACE",
    value: "ACE",
    title: "ACE",
  },
  {
    key: "Apps and Others",
    value: "Apps_And_Others",
    title: "Apps and Others",
  },
  {
    key: "D&S",
    value: "D&S",
    title: "D&S",
  },
  {
    key: "GCP",
    value: "GCP",
    title: "GCP",
  },
  lifeSciences,
  {
    key: "PPDC",
    value: "PPDC",
    title: "PPDC",
  },
  {
    key: "Travel",
    value: "Travel",
    title: "Travel",
  },
  {
    key: "YouTube",
    value: "YouTube",
    title: "YouTube",
  },
];

export const contentType: Data[] = [
  all,
  {
    key: "Help",
    value: "Help",
    title: "Help",
  },
  {
    key: "Marketing",
    value: "Marketing",
    title: "Marketing",
  },
  {
    key: "UI",
    value: "UI",
    title: "UI",
  },
  {
    key: "API",
    value: "API",
    title: "API",
  },
  {
    key: "Legal",
    value: "Legal",
    title: "Legal",
  },
  {
    key: "Other",
    value: "Other",
    title: "Other",
  },
  lifeSciences,
];

export const statusOptions: Data[] = [
  { key: "Yes", value: "Yes", title: "Yes" },
  { key: "No", value: "No", title: "No" },
  { key: "NA", value: "NA", title: "NA" },
];

export const tcOptions: Data[] = [
  { key: "Added", value: "Added", title: "Added" },
  { key: "Pending", value: "Pending", title: "Pending" },
  { key: "NA", value: "NA", title: "NA" },
];
