import React from "react";
import { useMercuryContext } from "../user-context";
import { Route, Routes, useLocation } from "react-router-dom";
import { Layout } from "antd";

import ProductAssignment from "../routes/ProductAssignment";
// TODO: Removing this unused import breaks webpack
// eslint-disable-next-line  no-unused-vars
// import DetailsPage from "../routes/DetailsPage";
import ManageUsers from "../routes/ManageUsers";
import ManageRoles from "../routes/ManageRoles";
import ManageApiClients from "../routes/ManageApiClients";
import ManageProducts from "../routes/ManageProducts";
import ManageCostCodes from "../routes/ManageCostCodes";
import ManageCurrencies from "../routes/ManageCurrencies";
import ManageProductAreas from "../routes/ManageProductAreas";
import InvoicingDetailsPage from "../routes/InvoicingDetailsPage";
import ExtensionRequests from "../routes/extensions/ExtensionsRequests";
import AdjustmentsPage from "../Search/Adjustments/Adjustments";
import LinguistManagementPage from "../routes/LinguistManagementPage";
import ManageHelixTemplates from "../routes/ManageHelixTemplates";
import ManageHelixTemplateMap from "../routes/ManageHelixTemplateMap";
import ManageExtensionJustifications from "../routes/ManageExtensionJustifications";
import ManageWorkflowTypes from "../routes/ManageWorkflowTypes";
import Notifications from "../stories/Notifications/Notifications";
import ReportsRoute from "../routes/Reports";

import Message from "../ui/Message";
import ProjectSearch from "../Search/Project/Project";
import PLPSearch from "../Search/PLP/PLP"
import DocumentSearch from "../Search/Document/Document"
import DownloadProject from "../routes/DownloadProject";
import QuarantineProjects from "../routes/QuarantineProjects";
import FailedDownload from "../routes/FailedDownload";
import ManageRates from "../routes/rates/ManageRates";
import SesameTracker from "../components/sesame/SesameTracker";
import SesameRequestDetails from "../components/sesame/SesameRequestDetails";

import Invoicing from "../Search/Invoicing/Invoicing";
import ManagePOTypes from "../routes/poTypes/ManagePOTypes";

function Content() {

  const
      { isAdmin, userDetails, flags } = useMercuryContext(),
      location = useLocation(),
      { Content } = Layout;

  // TODO: to be uncommented once we have assigned customer applications
  // const customerApplications = userDetails?.customerApplications.length || 0;

  const customerApplications = 1;


  return (
    <Content
      className="content-wrap"
    >
      <div className="site-layout-content">
        <Routes location={location}>

          {
            //@ts-ignore
            customerApplications === 0 ? (
            <Route
              path="/*"
              element={<Message
                  first={"Sorry"}
                  second={
                    "It appears you don't have access to any applications in Mercury, please contact support to request access."
                  }
                  loading={false}
                />
              }
            />
          ) :
          <>
          <Route
            path="*"
            element={<ProjectSearch />}
            />
          <Route
              path="/plps"
              element={<PLPSearch />}
              />
          <Route
              path="/documents"
              element={<DocumentSearch />}
              />
          <Route path="/invoicing" element={<Invoicing />} />
          <Route
            path="/invoicing-project/:id"
            element={<InvoicingDetailsPage />}
            />
          {(isAdmin || userDetails.vendorManager) && (
            <Route
            path="/linguist-management"
            element={<LinguistManagementPage />}
            />)}
          <Route
            path="/reports/*"
            element={<ReportsRoute/>}
            />
          <Route
            path="/extension-requests"
            element={<ExtensionRequests />}
            />
          {flags.adjustmentsTab && <Route
            path="/adjustments/*"
            element={<AdjustmentsPage />}
            />}
          {isAdmin && <Route
            path="/product-assignment"
            element={<ProductAssignment />}
            />}
          {isAdmin && <Route
            path="/manage-project-rates"
            element={<ManageRates />}
            />}
          {isAdmin && <Route
            path="/manage-users"
            element={<ManageUsers />}
            />}
          {<Route
            path="/manage-roles"
            element={<ManageRoles />}
            />}
          {isAdmin && <Route
            path="/manage-api-clients"
            element={<ManageApiClients />}
            />}
          {isAdmin && <Route
            path="/manage-products"
            element={<ManageProducts />}
            />}
          {isAdmin && <Route
            path="/manage-cost-codes"
            element={<ManageCostCodes />}
            />}
          {isAdmin && <Route
            path="/manage-currencies"
            element={<ManageCurrencies />}
            />}
          {isAdmin && <Route
            path="/manage-product-areas"
            element={<ManageProductAreas />}
            />}
          {isAdmin && <Route
            path="/manage-helix-templates"
            element={<ManageHelixTemplates />}
            />}
          {isAdmin && <Route
            path="/manage-helix-template-mappings"
            element={<ManageHelixTemplateMap />}
            />}
          {isAdmin && <Route
            path="/manage-extension-justifications"
            element={<ManageExtensionJustifications />}
          />}
          {isAdmin && <Route
            path="/manage-workflow-types"
            element={<ManageWorkflowTypes />}
          />}
          {isAdmin && <Route
            path="/manage-po-types"
            element={<ManagePOTypes />}
          />}
          {flags.manageDownload && <Route
              path="/downloadProject"
              element={<DownloadProject />}
            />}
          {flags.manageDownload && <Route
            path="/quarantined-projects"
            element={<QuarantineProjects />}
            />}
          {flags.manageDownload &&  <Route
            path="/failed-download"
            element={<FailedDownload />}
            />}
          <Route
            path="/notifications"
            element={<Notifications />}
            />
          {flags?.sesameAccount && <Route
            path="/sesame"
            element={<SesameTracker />}
          />}
          {flags?.sesameAccount && <Route
            path="/sesame/:id"
            element={<SesameRequestDetails />}
          />}
          </>
        }
        </Routes>
      </div>
      </Content>
  );
}

export default Content;
