import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, Radio, Select, Checkbox } from "antd";
import {
  programRoles,
  roles,
  languages,
  legoDragonLevels,
  productLine,
  contentType,
  Data,
} from "./Json";
import "./sesameAccount.scss";

const { Option } = Select;

interface FormData {
  requestType?: string;
  requesterEmail?: string;
  email?: string;
  password?: string;
  programRole?: string;
  languageCode?: string;
  fullName?: string;
  city?: string;
  sesameId?: string;
  additionalInstructions?: string;
  personalEmail?: string;
  legoDragonLevel?: string;
  fileSharingPolyglot?: string;
  includeInEmail?: string;
  linguistLevel?: string;
  affiliation?: string;
  productLine?: string;
  contentType?: string;
}

interface StepProps {
  next?: () => void;
  prev?: () => void;
  form: any;
  onFinish?: (values: FormData) => void;
}

const ProgramRole = ({ roles }: { roles: Data[] }) => {
  return (
    <Form.Item
      label="Program/Project Role"
      name="programRole"
      rules={[{ required: true, message: "Please select your role!" }]}
    >
      <Radio.Group>
        {roles.map((el: Data) => {
          return (
            <Form.Item key={el?.key} className="m-0">
              <Radio value={el?.value}>{el?.key}</Radio>
            </Form.Item>
          );
        })}
      </Radio.Group>
    </Form.Item>
  );
};

const LanguageCode = ({ label }: { label: string }) => {
  return (
    <Form.Item
      label={label}
      name="languageCode"
      rules={[{ required: true, message: "Please select language code!" }]}
      extra={
        <span className="extraText">
          GDT team members can select GDT option here. If your language code is
          not in the list, you can choose 'Other' and provide your language code
          in the 'Additional instructions for Training Team' field in this form.
        </span>
      }
    >
      <Select>
        {languages.map((el) => (
          <Option key={el?.code} value={el?.code}>
            {el?.code}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const FullName = () => {
  return (
    <Form.Item
      label="Full Name"
      name="fullName"
      rules={[{ required: true, message: "Please input your full name!" }]}
    >
      <Input />
    </Form.Item>
  );
};

const SesameId = () => {
  return (
    <Form.Item
      label="Sesame ID (Example 015-XX_XXXX@015vendor.com)"
      name="sesameId"
      rules={[
        {
          required: true,
          message: "Please input your Sesame ID!",
          type: "email",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};

const Instructions = () => {
  return (
    <Form.Item
      label="Additional Instructions for Training Team"
      name="additionalInstructions"
    >
      <Input.TextArea />
    </Form.Item>
  );
};

const FileAndEmailSharing = ({
  label,
  showNA = false,
  name,
}: {
  label: string;
  showNA?: boolean;
  name: string;
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        {
          required: true,
          message: "This field is required!",
        },
      ]}
      extra={
        <span className="extraText">
          GDT team can add list of languages that needs to be auto shared in
          Additional Instructions field below
        </span>
      }
    >
      <Radio.Group>
        <Form.Item className="m-0">
          <Radio value="Yes">Yes</Radio>
        </Form.Item>
        <Form.Item className="m-0">
          <Radio value="No">No</Radio>
        </Form.Item>
        {showNA && (
          <Form.Item className="m-0">
            <Radio value="NA">NA</Radio>
          </Form.Item>
        )}
      </Radio.Group>
    </Form.Item>
  );
};

const StepOne: React.FC<StepProps> = ({ next, form }) => (
  <Form form={form} layout="vertical" onFinish={next}>
    <Form.Item
      label="Request Type"
      name="requestType"
      rules={[{ required: true, message: "Please select request type!" }]}
    >
      <Radio.Group>
        <Form.Item className="m-0">
          <Radio value="newSesame">New Sesame Request</Radio>
        </Form.Item>
        <Form.Item className="m-0">
          <Radio value="suspension">Suspension Request</Radio>
        </Form.Item>
        <Form.Item className="m-0">
          <Radio value="reactivation">Reactivation Request</Radio>
        </Form.Item>
      </Radio.Group>
    </Form.Item>
    <Form.Item
      label="Requester Email ID"
      name="requesterEmail"
      rules={[
        { required: true, message: "Please input your email!", type: "email" },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item className="alignRight">
      <Button type="primary" htmlType="submit">
        Next
      </Button>
    </Form.Item>
  </Form>
);

const StepTwo: React.FC<StepProps> = ({ next, prev, form, onFinish }) => {
  const requestType = form.getFieldValue("requestType");

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={
        requestType === "suspension" || requestType === "reactivation"
          ? onFinish
          : next
      }
    >
      {requestType === "suspension" && (
        <>
          <ProgramRole roles={programRoles} />
          <LanguageCode label="Language Code (For Translators/Language Lead/GDT/LQE)" />
          <FullName />
          <SesameId />
          <Instructions />
        </>
      )}
      {requestType === "newSesame" && <ProgramRole roles={roles} />}
      {requestType === "reactivation" && (
        <>
          <ProgramRole roles={programRoles} />
          <LanguageCode label="Language Code (For Translators/Language Lead/GDT/LQE)" />
          <FullName />
          <Form.Item
            label="Personal email address"
            name="personalEmail"
            rules={[
              {
                required: true,
                message: "Please input your personal email ID!",
                type: "email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <SesameId />
          <Form.Item
            label="Lego dragon level"
            name="legoDragonLevel"
            rules={[
              {
                required: true,
                message: "Please select your lego dragon level!",
              },
            ]}
          >
            <Radio.Group>
              {legoDragonLevels.map((el) => {
                return (
                  <Form.Item key={el?.key} className="m-0">
                    <Radio value={el?.value}>{el?.key}</Radio>
                  </Form.Item>
                );
              })}
            </Radio.Group>
          </Form.Item>
          <FileAndEmailSharing
            showNA
            label="Automated file sharing in Polyglot (For Translator/Language Lead/GDT)"
            name="fileSharingPolyglot"
          />
          <FileAndEmailSharing
            showNA
            label="Include in HO emails and other email communications (For Translator/Language Lead/GDT)"
            name="includeInEmail"
          />
          <Instructions />
        </>
      )}
      <Form.Item className="alignRight">
        <Button type="default" onClick={prev} className="mr-8">
          Back
        </Button>
        <Button type="primary" htmlType="submit">
          {requestType === "suspension" || requestType === "reactivation"
            ? "Submit"
            : "Next"}
        </Button>
      </Form.Item>
    </Form>
  );
};

const StepThree: React.FC<StepProps> = ({ prev, form, onFinish }) => {
  const role = form.getFieldValue("programRole");

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      {(role === "Linguist" || role === "GDT Lead") && (
        <>
          <Form.Item
            label="Linguist Level"
            name="linguistLevel"
            rules={[
              { required: true, message: "Please select linguist level!" },
            ]}
          >
            <Radio.Group>
              <Form.Item className="m-0">
                <Radio value="Translator">Translator</Radio>
              </Form.Item>
              <Form.Item className="m-0">
                <Radio value="Language Lead">Language Lead</Radio>
              </Form.Item>
              <Form.Item className="m-0">
                <Radio value="GDT">GDT</Radio>
              </Form.Item>
            </Radio.Group>
          </Form.Item>
          <LanguageCode label="Language Code" />
          <Form.Item
            label="Affiliation"
            name="affiliation"
            rules={[{ required: true, message: "Please select affiliation!" }]}
          >
            <Radio.Group>
              <Form.Item className="m-0">
                <Radio value="RWS Internal">RWS Internal</Radio>
              </Form.Item>
              <Form.Item className="m-0">
                <Radio value="External">External</Radio>
              </Form.Item>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Select Product Line"
            name="productLine"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Checkbox.Group>
              {productLine.map((el) => {
                return (
                  <Form.Item key={el?.key} className="m-0">
                    <Checkbox value={el?.value}>{el?.key}</Checkbox>
                  </Form.Item>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            label="Content type"
            name="contentType"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Checkbox.Group>
              {contentType.map((el) => {
                return (
                  <Form.Item key={el?.key} className="m-0">
                    <Checkbox value={el?.value}>{el?.key}</Checkbox>
                  </Form.Item>
                );
              })}
            </Checkbox.Group>
          </Form.Item>
          <FileAndEmailSharing
            label="Automated file sharing in Polyglot"
            name="fileSharingPolyglot"
          />
          <FileAndEmailSharing
            name="includeInEmail"
            label="Include in HO emails and other email communications"
          />
        </>
      )}
      <FullName />
      {(role === "Tester" ||
        role === "LQE subtitling" ||
        role === "LQE non subtitling") && (
        <>
          <Form.Item
            label="Linguist Level"
            name="linguistLevel"
            rules={[
              {
                required: true,
                message: "This field is required!",
              },
            ]}
          >
            <Radio.Group>
              <Form.Item className="m-0">
                <Radio value="LQE subtitling">LQE subtitling</Radio>
              </Form.Item>
              <Form.Item className="m-0">
                <Radio value="LQE non subtitling">LQE non subtitling</Radio>
              </Form.Item>
              <Form.Item className="m-0">
                <Radio value="Tester">Tester</Radio>
              </Form.Item>
            </Radio.Group>
          </Form.Item>
          <LanguageCode label="Language Code" />
        </>
      )}
      <Form.Item label="City, Country" name="city">
        <Input />
      </Form.Item>
      <Form.Item
        label="Email ID of new resource"
        name="emailId"
        extra={<span className="extraText">It should be always RWS ID</span>}
        rules={[
          {
            required: true,
            message: "Please input your email ID!",
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Instructions />
      <Form.Item className="alignRight">
        <Button type="default" onClick={prev} className="mr-8">
          Back
        </Button>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

interface MultiStepFormProps {
  visible: boolean;
  onClose: (resetFields: () => void) => void;
  onSubmit: (data: FormData, resetFields: () => void) => void;
  requesterEmail: string;
}

const SesameRequest: React.FC<MultiStepFormProps> = ({
  visible,
  onClose,
  onSubmit,
  requesterEmail,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<FormData>({ requesterEmail });

  useEffect(() => {
    form.setFieldsValue({ requesterEmail });
  }, [form, requesterEmail]);

  const handleNext = async () => {
    try {
      const values = await form.validateFields();
      setFormData((prev) => ({ ...prev, ...values }));
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const resetFields = () => {
    setCurrentStep(0);
    form.resetFields();
    form.setFieldsValue({ requesterEmail });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const finalData = { ...formData, ...values };
      onSubmit(finalData, resetFields);
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };

  const steps = [
    {
      title: "Step 1",
      content: <StepOne next={handleNext} form={form} />,
    },
    {
      title: "Step 2",
      content: (
        <StepTwo
          next={handleNext}
          prev={handlePrev}
          form={form}
          onFinish={handleSubmit}
        />
      ),
    },
    {
      title: "Step 3",
      content: (
        <StepThree prev={handlePrev} form={form} onFinish={handleSubmit} />
      ),
    },
  ];

  return (
    <Modal open={visible} onCancel={() => onClose(resetFields)} footer={null}>
      {steps[currentStep].content}
    </Modal>
  );
};

export default SesameRequest;
