import { BASE_PATH, API_VERSION } from './config';
import moment from 'moment';
import axios from 'axios';
import productAreaMock from '../__mocks__/productAreaMock';
import productTypeMock from '../__mocks__/productTypeMock';

export const postGExtensionRequests = async (
  filtersValues,
  currentPage,
  projectsPerPage,
  nextToken,
  accessToken,
) => {

  const {
    justification,
    extensionRequestId,
    requestedDueDateFrom,
    requestedDueDateTo,
    createdDateFrom,
    createdDateTo,
    shortId,
    status,
    product,
    productArea,
    sortColumn,
    sortOrder,
    bulk,
  } = filtersValues;
  // Need to ensure that the params are not empty

    // v1
  // const dates = {
  //   ...(requestedDueDateFrom || requestedDueDateTo
  //     ? {
  //         requestedDueDate: {
  //           ...(requestedDueDateFrom
  //             ? {
  //                 requestedDueDateFrom:
  //                   moment(requestedDueDateFrom).format('DD-MM-YYYYT00:00:00'),
  //               }
  //             : {}),
  //           ...(requestedDueDateTo
  //             ? {
  //                 requestedDueDateTo:
  //                   moment(requestedDueDateTo).format('DD-MM-YYYYT23:59:59'),
  //               }
  //             : {}),
  //         },
  //       }
  //     : {}),
  //   ...(createdDateFrom || createdDateTo
  //     ? {
  //         created: {
  //           ...(createdDateFrom
  //             ? {
  //                 createdFrom: moment(createdDateFrom).format('DD-MM-YYYYT00:00:00'),
  //               }
  //             : {}),
  //           ...(createdDateTo
  //             ? {
  //                 createdTo: moment(createdDateTo).format('DD-MM-YYYYT23:59:59'),
  //               }
  //             : {}),
  //         },
  //       }
  //     : {}),
  // };

  // const properties = {
  //   ...(extensionRequestId ? { extensionRequestId: extensionRequestId } : {}),

  //   ...(shortId && shortId.length > 0 ? { shortId: shortId } : {}),
  //   ...(justification !== undefined ? { justification: justification } : {}),
  //   ...(status ? { status: status } : {}),
  //   ...(product ? { product: product } : {}),
  //   ...(productArea ? { productArea: productArea } : {}),
  //   ...(bulk !== undefined ? { bulk: bulk } : {}),
  //   ...((requestedDueDateFrom && requestedDueDateTo) || (createdDateFrom && createdDateTo)
  //     ? { dates: dates }
  //     : {}),
  // };
  // const params = {
  //   properties,

  //   ...(sortColumn && sortOrder ? { sortColumn: sortColumn } : {}),
  //   ...(sortOrder ? { sortOrder: sortOrder === 'ascend' ? 'asc' : 'desc' } : {}),
  //   ...(currentPage > 1 && nextToken !== '' ? { nextToken: nextToken } : {}),
  //   ...(projectsPerPage ? { limit: projectsPerPage } : {}),
  // };

  //v2
  const params= {
      filter: {shortId: shortId},
      sort: {createdAt: -1},
      pagination: {
          pageNumber: 1,
          resultsPerPage: 500
      }
  };  

      // v1
      // const res = await axios.post(
      //     `${BASE_PATH()}/${API_VERSION}/ges/extension/filter`,
      //     params,
      //     {
      //         headers: {
      //             'Content-Type': 'application/json',
      //             Authorization: `Bearer ${accessToken}`,
      //           },
      //         }
      //       );

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/google-ges-v1/search`, params,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return { extensionRequests: res.data.documents };

  } catch (error) {
    console.error(error);
  }

};

export const getExtensionRequestById = async (
  requestId,
  accessToken,
) => {
  const res = await axios.get(
    `${BASE_PATH()}/${API_VERSION}/ges/extension/${requestId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  
  return res.data;
};

export const getProductAreas = async (baseUrl, accessToken) => {
  let res = { data: {} };
  if (baseUrl !== '' && baseUrl !== undefined) {
    res = await axios
      .get(`${baseUrl}/productArea`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch(() => {
        const res = { data: productAreaMock, code: 404 };
        return res;
      });
  } else {
    res.data = productAreaMock;
  }

  return res;
};
export const getProductTypes = async (baseUrl, accessToken) => {
  let res = { data: {} };
  if (baseUrl !== '' && baseUrl !== undefined) {
    res = await axios
      .get(`${baseUrl}/productTypes`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .catch(() => {
        const res = { data: productTypeMock, code: 404 };
        return res;
      });
  } else {
    res.data = productTypeMock;
  }

  return res;
};
