import React, { useState } from "react";
import { Button } from "antd";
import SesameRequest from "./SesameRequest";

interface FormData {
  username?: string;
  email?: string;
  password?: string;
}

export interface SesameAccountDialogProps {
  onSubmit: (data: FormData, callback: () => void) => void;
  buttonLabel: string;
  requesterEmail: string;
}

const SesameAccountDialog: React.FC<SesameAccountDialogProps> = ({
  onSubmit,
  buttonLabel,
  requesterEmail,
}) => {
  const [visible, setVisible] = useState(false);

  const toggleModal = () => {
    setVisible(!visible);
  };

  const onSuccess = (resetFields: () => void) => {
    resetFields();
    toggleModal();
  };

  const onClose = (resetFields: () => void) => {
    resetFields();
    setVisible(false);
  };

  const handleFormSubmit = (data: FormData, resetFields: () => void) => {
    onSubmit(data, () => onSuccess(resetFields));
  };

  return (
    <div>
      <Button type="primary" onClick={() => toggleModal()}>
        {buttonLabel}
      </Button>
      {visible ? (
        <SesameRequest
          visible={visible}
          onClose={onClose}
          onSubmit={handleFormSubmit}
          requesterEmail={requesterEmail}
        />
      ) : null}
    </div>
  );
};

export default SesameAccountDialog;
